// 富文本编辑器

<template>
  <div id="editor">
    <div id="editor-bar" class="toolbar"></div>
    <div id="editor-content" class="content"></div>
  </div>
</template>

<script>
import E from 'wangeditor'
import axios from 'axios'

export default {
  name: '',
  components: {},

  props: {
    // v-model 双向绑定：接受父组件的值
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      editor: null,
      IMG_API: process.env.VUE_APP_BASE_API + '/serve-user/app-api/v1/upload'
    }
  },

  computed: {},

  watch: {
    value: {
      handler(value) {
        // 如果父组件传入了值，就赋值给编辑器。在这写是为了解决网络较慢时
        if (!this.editor.txt.html()) {
          this.editor.txt.html(value)
        }
      }
      // immediate: true
      // deep: true
    }
  },

  created() {},

  mounted() {
    this.init()
  },

  methods: {
    init() {
      // this.editor = new E('#editor')
      this.editor = new E('#editor-bar', '#editor-content')

      this.editor.config.focus = false // 取消自动 focus
      this.editor.config.zIndex = 500 // 配置 z-index

      // v-model 双向绑定：把值发送到父组件
      this.editor.config.onchange = (html) => {
        this.$emit('input', html)
      }

      // v-model 双向绑定：把值发送到父组件（不占用用户的 onchange 配置）
      // this.editor.txt.eventHooks.changeEvents.push(() => {
      //   this.$emit('input', this.editor.$textElem.html())
      // })

      // 自定义上传图片功能
      this.editor.config.customUploadImg = (files, insertImgFn) => {
        this.uploadRequests(files, insertImgFn, files.length, 0)
      }

      // 自定义上传视频功能
      this.editor.config.customUploadVideo = (resultFiles, insertVideoFn) => {
        this.uploadRequest(resultFiles, insertVideoFn)
      }

      this.editor.create()

      // 如果父组件传入了值，就赋值给编辑器
      if (this.value) {
        this.editor.txt.html(this.value)
      }
    },

    // 上传文件
    uploadRequest(files, insertFn) {
      files.forEach(async (item) => {
        const formData = new FormData()
        formData.append('attach', item)

        const res = await axios.post(this.IMG_API, formData, {
          headers: { Authorization: localStorage.getItem('token') }
        })

        if (res.data.code === '00000') {
          insertFn(res.data.data[0])
        }
      })
    },
    async uploadRequests(files, insertFn, lengths, i) {
      const formData = new FormData()
      formData.append('attach', files[i])

      const res = await axios.post(this.IMG_API, formData, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.data.code === '00000') {
        insertFn(res.data.data[0])
        if (i === lengths - 1) {
        } else {
          this.uploadRequests(files, insertFn, lengths, i + 1)
        }
      }
    }
  },

  beforeDestroy() {
    if (this.editor) {
      // 销毁编辑器
      this.editor.destroy()
      this.editor = null
    }
  }
}
</script>

<style scoped lang="scss">
#editor {
  .toolbar {
    border: 1px solid #ccc;
  }
  .content {
    min-height: 300px;
    border: 1px solid #ccc;
    border-top: none;
  }
}
</style>
